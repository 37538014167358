import styled from '@emotion/styled'
import { Box, Typography, Button } from '@mui/material'
import { height } from '@mui/system'
export namespace P {
  export const PurchaseSurveyContainer = styled(Box)(({ theme }: any) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#F1F2F6',
    borderRadius: '15px !important',
    outline: 'none',
    border: 'none',
    boxShadow: 'none',
    width: '57.0625rem',
    height: '30.875rem',

    color: theme.palette.text.primary,
    // [theme.breakpoints.up('md')]: {
    //   minHeight: 494,
    //   minWidth: 913,
    // },

    [theme.breakpoints.down('md')]: {
      width: '90%',
      height: '42rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      // maxHeight: '95vh',
      height: '80vh',
    },
  }))
  export const PurchaseSurvey = styled(Box)(({ theme }: any) => ({
    width: '100%',
    // height: '6.2rem',
    position: 'relative',
  }))
  export const SkipSurveyContainer = styled(Box)(({ theme }: any) => ({
    height: '1rem',
  }))

  export const SubContainer = styled(Box)(({ theme }: any) => ({
    padding: '5rem 6rem 2rem 6rem',
    display: 'flex',
    alignItems: 'center',
    height: '380px',
    justifyContent: 'space-around',
    flexDirection: 'column',
    gap: '3rem',
    // background: 'orange',
    [theme.breakpoints.down('md')]: {
      height: '35rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '2rem',
      top: '2rem',
      height: '40rem',
      gap: '2rem',
    },
    [theme.breakpoints.down(376)]: {
      height: '29rem',
      justifyContent: 'space-around',
      display: 'flex',
      gap: '0',
    },
  }))

  export const DisableButton = styled(Button)(({ theme }: any) => ({
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '1.25rem',
    borderRadius: '0.5rem',
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.75,
    },
    '&:disabled': {
      background: '#C5C5C5',
      color: theme.palette.primary.contrastText,
    },
  }))
  export const SubContainerHeader = styled(Box)(({ theme }: any) => ({
    position: 'absolute',
    color: '#18BC46',
    fontSize: '1rem',
    top: '2rem',
    right: '2rem',
    cursor: 'pointer',
  }))

  export const SubContainerTitle = styled(Typography)(({ theme }: any) => ({
    // lineHeight: '2.7669rem',
    fontWeight: '700',
    fontSize: 'calc(22px + (20 -22) * ((100vw - 300px) / (1600 - 300)))',

    textAlign: 'center',
  }))
  export const StarReview = styled(Box)(({ theme }: any) => ({
    gap: '2rem',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    img: {
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        width: 'calc(50px + (20 -50) * ((100vw - 300px) / (1600 - 300)))',
        height: 'auto',
      },
    },
  }))
  export const CarouselContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.565rem',
  }))
  export const CarouselStep = styled(Box)(({ theme }: any) => ({
    width: '4.5625rem',
    height: '0.3125rem',
    background: '#18BC46',
    cursor: 'pointer',
  }))
  export const ErrorText = styled(Typography)(({ theme }: any) => ({
    color: 'red',
    fontSize: '0.75rem',
  }))
  export const FeedbackInput = styled('textarea')(({ theme }: any) => ({
    background: '#ced6e040',
    color: '#66717B',
    borderRadius: '0.5rem',
    padding: '1rem 0.75rem',
    fontWeight: 400,
    fontSize: '1.125rem !important',
    lineHeight: '1.4375rem !important',
    outline: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'start',
    height: '119px',
    textAlign: 'start',
    width: '681px',
    '&::placeholder': {
      '&::after': {
        content: '"*"',
        color: 'red',
      },
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'auto',
    },
  }))
}
